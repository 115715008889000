// Conveniently import this file anywhere to use db

import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA8i2UF1zn7gxRxkA3UXN3VFdR7JeOkQIA",
    authDomain: "ktuapp-8a6db.firebaseapp.com",
    databaseURL: "https://ktuapp-8a6db.firebaseio.com",
    projectId: "ktuapp-8a6db",
    storageBucket: "ktuapp-8a6db.appspot.com",
    messagingSenderId: "71187591271",
    appId: "1:71187591271:web:ce47728aafd26238fa64c2",
    measurementId: "G-9NDCGF4NTX"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.database();
const storage = app.storage();
export default db
export { app, storage }


// Export types that exists in Firestore - Uncomment if you need them in your app
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }

<template>
    <BaseLayout>
        <div class="text-center">
            <img src="@/assets/app_logo.png" alt="" style="max-width: 200px;">
        </div>
        <v-card class="cursor-pointer rounded-xl" @click="$router.push('/advertisements')">
            <v-card-title class="d-flex justify-space-between">
                <h4>Advertisements</h4>
                <v-icon>mdi-chevron-right</v-icon>
            </v-card-title>
        </v-card>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
export default {
    components: {
        BaseLayout,
    }
}
</script>

<style>
</style>
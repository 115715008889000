<template>
    <v-container style="height: 100%">
        <v-row style="height: 100%" justify="center">
            <v-col cols="11" sm="6" md="5" lg="4" style="height: 100%;">
                <div style="height: 100%;" class="d-flex flex-column"
                    :class="[justify != null ? 'justify-' + justify : 'justify-space-around', alignCenter ? 'align-center' : '']"
                    :style="{'padding': padding?padding:'10px 0'}">
                    <slot></slot>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BaseLayout',
    props: ['justify', 'alignCenter', 'padding']
}
</script>
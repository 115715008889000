<template>
    <v-snackbar v-model="$store.state.snackBar.show" :timeout="snackBar.timeout" :color="snackBar.color">
        {{ snackBar.text }}
    </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'SnackBar',
    computed: {
        ...mapState(['snackBar'])
    },
    methods: {
        showSnackBar(text, color = 'success', timeout = 2000) {
            this.text = text;
            this.color = color;
            this.timeout = timeout;
            this.show = true;
        }
    },
}
</script>
<style>
</style>
<template>
    <BaseLayout>
        <div class="text-center">
            <img src="@/assets/app_logo.png" alt="" style="max-width: 200px;">
        </div>
        <v-card class="rounded-xl">
            <v-card-title class="justify-center">Verification</v-card-title>
            <v-card-text>
                <v-form ref="mobileNumberForm" v-show="state < States.OtpRequested" v-model="mobileNumberFormValid">
                    <v-text-field label="Mobile Number" :rules="[rules.required, rules.mobileNumber]" outlined
                        v-model="phoneNumber" placeholder="----------" rounded maxlength="10">
                        <template #prepend>
                            +91
                        </template>
                    </v-text-field>
                    <div id="recaptcha-container" class="d-flex justify-center mb-3"></div>
                    <v-btn rounded color="secondary" large block class="mb-2" @click="requestOtp"
                        :disabled="!(mobileNumberFormValid && state >= States.CanRequestOtp)"
                        :loading="state == States.RequestingOtp">Get OTP</v-btn>
                </v-form>
                <v-form ref="otpForm" v-show="state >= States.OtpRequested" v-model="OtpFormValid">
                    <v-otp-input length="6" type="number" v-model="code" ></v-otp-input>
                    <p class="text-center">Didn't receive the verification OTP?<a href="javascript: void(0);"
                            @click="state=States.Init"> Resend OTP</a></p>
                    <v-btn rounded color="secondary" large block class="mb-2" :disabled="!OtpFormValid"
                        :loading="state == States.SubmittingOtp" @click="submitOtp">Submit</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </BaseLayout>
</template>
<script>
import BaseLayout from '@/components/BaseLayout.vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import db from '@/firebase';
const States = Object.freeze({
    Init: 0,
    CanRequestOtp: 1,
    RequestingOtp: 2,
    OtpRequested: 3,
    CanSubmitOtp: 4,
    SubmittingOtp: 5,
    OtpSubmitted: 6,
});

export default {
    data() {
        return {
            rules: {
                required: (value) => !!value || 'This field is required.',
                mobileNumber: (value) => value.match(/^\d{10}$/) || 'Enter a valid mobile number',
            },
            mobileNumberFormValid: false,
            OtpFormValid: false,
            phoneNumber: '',
            code: '',
            state: States.Init,
            allowedUids: null,
        }
    },
    components: {
        BaseLayout,
    },
    computed: {
        States() {
            return States;
        }
    },
    methods: {
        requestOtp() {
            if (!(this.state >= States.CanRequestOtp && this.mobileNumberFormValid)) return;
            this.state = States.RequestingOtp;
            const phoneNumber = '+91' + this.phoneNumber;
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    console.log(confirmationResult);
                    window.confirmationResult = confirmationResult;
                    this.$store.commit('showSnackBar', { text: 'OTP was successfully sent.' })
                    this.state = States.OtpRequested;
                    // ...
                    // eslint-disable-next-line
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    this.$store.commit('showSnackBar', { text: 'Could\'nt send OTP.', color: 'red' })
                    this.state = States.CanRequestOtp;
                });
        },
        submitOtp() {
            if (!this.OtpFormValid) return;
            this.state = States.SubmittingOtp;
            window.confirmationResult.confirm(this.code).then(async (result) => {
                // User signed in successfully.
                // eslint-disable-next-line
                const user = result.user;
                console.log(result);
                if (!Object.entries(this.allowedUids).find(([uid, allowed]) => uid == user.uid && allowed)) {
                    this.$store.commit('showSnackBar', { text: 'Unauthorized access.', color: 'red' });
                    this.$refs.mobileNumberForm.reset();
                    this.$refs.otpForm.reset();
                    this.state = States.CanRequestOtp;
                    return;
                }
                this.$store.commit('authenticateUser', user);
                this.state = States.OtpSubmitted;
                this.$store.commit('showSnackBar', { text: 'You are now logged in.' });
                this.$router.push({ name: 'Home' });
                // ...
                // eslint-disable-next-line
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                this.$store.commit('showSnackBar', { text: 'Invalid OTP.', color: 'red' });
                this.state = States.OtpRequested;
            });
        }
    },
    created() {
        db.ref('allowedUids').once('value', (snapshot) => {
            this.allowedUids = snapshot.val();
        });
    },
    mounted() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'normal',
            callback: (response) => {
                console.log(response);
                this.state = States.CanRequestOtp;
            },
        });
        window.recaptchaVerifier.render().then((widgetId) => {
            // eslint-disable-next-line
            window.recaptchaWidgetId = widgetId;

            // const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId);
            // console.log(recaptchaResponse);
        });
    }
}
</script>
<style>
</style>
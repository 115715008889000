<template>
    <BaseLayout justify="start">
        <v-app-bar app color="black" dark>
            <v-toolbar-title class="text-capitalize">Advertisements</v-toolbar-title>
        </v-app-bar>
        <v-card class="cursor-pointer rounded-xl mb-4">
            <v-card-title class="d-flex justify-space-between">
                <h4>Powered by</h4>
                <v-icon>mdi-chevron-right</v-icon>
            </v-card-title>
        </v-card>
        <v-card class="cursor-pointer rounded-xl mb-4" @click="$router.push('/advertisements/banner')">
            <v-card-title class="d-flex justify-space-between">
                <h4>Banner Ads</h4>
                <v-icon>mdi-chevron-right</v-icon>
            </v-card-title>
        </v-card>
        <v-card class="cursor-pointer rounded-xl" @click="$router.push('/advertisements/carousel')">
            <v-card-title class="d-flex justify-space-between">
                <h4>Carousel Ads</h4>
                <v-icon>mdi-chevron-right</v-icon>
            </v-card-title>
        </v-card>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue';
export default {
    components: { BaseLayout }
}
</script>

<style>
</style>
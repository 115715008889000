import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    snackBar: {
      show: false,
      text: '',
      color: 'success',
      timeout: 2000,
    }
  },
  getters: {
    isAuthenticated({ currentUser }) {
      return !!currentUser;
    },
    currentUser({ currentUser }) {
      return currentUser;
    }
  },
  mutations: {
    authenticateUser(state, payload) {
      // eslint-disable-next-line
      state.currentUser = payload;
    },
    logout(state) {
      // eslint-disable-next-line
      state.currentUser = null;
    },
    showSnackBar({ snackBar }, { text, color = 'success', timeout = 2000 }) {
      snackBar.text = text;
      snackBar.color = color;
      snackBar.timeout = timeout;
      snackBar.show = true;
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
    <BaseLayout justify="start">
        <v-app-bar app color="black" dark>
            <v-toolbar-title class="text-capitalize">{{ isNew ? 'New' : 'Edit' }} {{ type }} Ad</v-toolbar-title>
        </v-app-bar>
        <v-form ref="form" v-model="valid" @submit.prevent="null">
            <div class="mb-4 rounded image-container" v-if="form.file || !isNew"
                :style="{ backgroundImage: `url('${getDisplayImage}')`, ...imageContainerStyle }"></div>
            <v-file-input show-size truncate-length="20" outlined placeholder="Select Image" rounded accept="image/*"
                v-model="form.file" :rules="[(f) => !isNew || f != null || 'Image is required']">
            </v-file-input>
            <v-text-field label="URL" placeholder="Enter URL" outlined rounded v-model="form.url"
                :rules="[rules.validUrl]"></v-text-field>
            <v-btn color="red" rounded outlined large block :disabled="loading" @click="deleteDialog=true" class="mb-3" v-if="!isNew"> Delete </v-btn>
            <v-btn color="secondary" rounded large block :loading="loading" @click="submit" :disabled="deleteLoading"> Submit </v-btn>
        </v-form>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card class="pa-4">
                <v-card-title>Delete Ad?</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this advertisement?
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="deleteDialog=false">No</v-btn>
                    <v-btn color="red" @click="deleteAd" :loading="deleteLoading">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue';
import db, { storage } from '@/firebase';
export default {
    components: { BaseLayout },
    data() {
        return {
            form: {
                file: null,
                url: ''
            },
            ad: {},
            currentImageURL: null,
            deleteDialog: false,
            overlay: false,
            valid: false,
            loading: false,
            deleteLoading: false,
            rules: {
                //eslint-disable-next-line
                validUrl: value => !value || value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) || 'Enter a valid URL'
            }
        }
    },
    methods: {
        fileToUrl(file) {
            return URL.createObjectURL(file);
        },
        async submit() {
            if (!this.valid) return;
            this.loading = true;
            var imageEntry;
            if (this.form.file) {
                const nameArr = this.form.file.name.split('.');
                const fileRef = storage.ref(`advertisements/${this.type}/${btoa(new Date().toISOString())}.${nameArr[nameArr.length - 1]}`);
                const fileSnapshot = await fileRef.put(this.form.file);
                imageEntry = {
                    fullPath: fileSnapshot.metadata.fullPath,
                    name: fileSnapshot.metadata.name,
                    downloadUrl: await fileRef.getDownloadURL(),
                }
            }
            if (this.isNew) {
                await db.ref(`advertisements/${this.type}/items`).push({
                    url: this.form.url,
                    image: imageEntry
                });
            } else {
                if (this.form.url != this.ad.url || this.form.file) {
                    const payload = {};
                    if (this.form.url != this.ad.url) {
                        payload['url'] = this.form.url;
                    }
                    if (imageEntry) {
                        payload['image'] = imageEntry;
                        storage.ref(this.ad.image.fullPath).delete();
                    }
                    await db.ref(`advertisements/${this.type}/items`).child(this.id).update(payload);
                }
            }
            this.$refs.form.reset();
            this.loading = false;
            if (window.history.length > 1)
                this.$router.back();
            else
                this.router.push(`/advertisements/${this.type}`);
        },
        async deleteAd() {
            if(this.isNew) return;
            this.deleteLoading = true;
            try{
                await db.ref(`advertisements/${this.type}/items`).child(this.id).remove();
                if(this.ad.image.fullPath) storage.ref(this.ad.image.fullPath).delete();
                this.$router.back();
            }catch (e){
                alert('Could\'nt delete advertisement.');
            }
            this.deleteLoading = false;
        },
        setCurrentImageURL() {
            const base64img = new URLSearchParams(window.location.search).get('i');
            if (base64img)
                this.currentImageURL = atob(base64img);
            else
                this.currentImageURL = this.ad.image.downloadUrl;
        }
    },
    computed: {
        type() {
            return this.$route.params.type;
        },
        isNew() {
            return this.$route.name === 'NewAdView';
        },
        id() {
            return this.$route.params.id;
        },
        getDisplayImage() {
            if (this.form.file)
                return this.fileToUrl(this.form.file);
            else if (!this.isNew) return this.currentImageURL;
            return null;
        },
        imageContainerStyle() {
            return { paddingTop: this.type === 'banner' ? '16.5%' : '56.25%' }
        }
    },
    // eslint-disable-next-line
    beforeRouteEnter(to, from, next) {
        if (!['banner', 'carousel'].includes(to.params.type)) {
            return next(false);
        }
        next(true);
     },
     // eslint-disable-next-line
    beforeRouteUpdate(to, from, next) {
        if (!['banner', 'carousel'].includes(to.params.type)) {
            return next(false);
        }
        next(true);
    },
    async created() {
        if (!this.isNew) {
            this.overlay = true;
            const snapshot = await db.ref(`advertisements/${this.type}/items`).child(this.id).once('value');
            this.ad = snapshot.val();
            this.form.url = this.ad.url;
            await this.setCurrentImageURL();
            this.overlay = false;
        }
    }
}
</script>

<style>
.image-container {
    width: 100%;
    background-size: contain;
    background-position: center;
    border: 2px dashed grey;
}
</style>
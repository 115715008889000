import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { capitalizeFirstLetter } from '@/utils'

import HomeView from '@/views/HomeView.vue'
import AdsHomeView from '@/views/AdsHomeView.vue'
import AdsListView from '@/views/AdsListView.vue'
import AdFormView from '@/views/AdFormView.vue'
import LoginView from '@/views/LoginView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      authRequired: false,
      title: 'Login',
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      authRequired: true,
      title: 'Home',
    }
  },
  {
    path: '/advertisements',
    name: 'Ads',
    component: AdsHomeView,
    meta: {
      authRequired: true,
      title: 'Advertisements',
    }
  },
  {
    path: '/advertisements/:type',
    name: 'AdsListView',
    component: AdsListView,
    meta: {
      authRequired: true,
      title: () => capitalizeFirstLetter(router.currentRoute.params.type),
    }
  },
  {
    path: '/advertisements/:type/new',
    name: 'NewAdView',
    component: AdFormView,
    meta: {
      authRequired: true,
      title: () => 'New ' + capitalizeFirstLetter(router.currentRoute.params.type),
    }
  },
  {
    path: '/advertisements/:type/:id',
    name: 'EditAdView',
    component: AdFormView,
    meta: {
      authRequired: true,
      title: () => 'Edit ' + capitalizeFirstLetter(router.currentRoute.params.type),
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

const TITLE = 'My KTU Admin'

router.beforeEach((to, from, next) => {
  if (to.meta?.authRequired === true) {
    if (store.getters.isAuthenticated) return next(true); else return next({ name: 'Login' });
  } else if (to.meta?.authRequired === false) {
    if (!store.getters.isAuthenticated) return next(true); else return next({ name: 'Home' });
  }
  return next(true);
});

// eslint-disable-next-line
router.afterEach((to, from) => {
  document.title = TITLE + (to.meta?.title != null ? ` - ${typeof to.meta.title === 'function' ? to.meta.title() : to.meta.title}` : '');
})

export default router

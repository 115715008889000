<template>
    <BaseLayout justify="start">
        <v-app-bar app color="black" dark>
            <v-toolbar-title class="text-capitalize">{{ type }} Ads</v-toolbar-title>
        </v-app-bar>
        <v-switch v-model="enabled" @change="switchStatus" v-if="!overlay"
            :label="`${type[0].toUpperCase() + type.slice(1)} ads are ${enabled ? 'enabled' : 'disabled'}`">
        </v-switch>
        <v-btn elevation="2" fab style="position: fixed;bottom:20px;right:20px;"
            @click="$router.push(`/advertisements/${type}/new`)">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-card v-for="(ad, key) in ads" :key="key" class="mb-3 rounded-xl pa-3">
            <v-row align="center">
                <v-col cols="3" class="cursor-pointer">
                    <img :src="ads[key].image.downloadUrl" alt="Banner Ad" class="card-leading" v-if="ads[key].image.downloadUrl"
                        @click="showDialog(key)">
                    <div class="text-center" v-else>
                        <v-progress-circular width="2" size="20" indeterminate color="primary">
                        </v-progress-circular>
                    </div>
                </v-col>
                <v-col cols="7">
                    <a :href="ad.url" target="_blank" class="body-2">{{ ad.url }}</a>
                </v-col>
                <v-col cols="2"
                    @click="$router.push(`/advertisements/${type}/${key}`)"
                    class="cursor-pointer">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-col>
            </v-row>
        </v-card>
        <div style="height:100%" class="d-flex align-center justify-center" v-if="Object.keys(ads).length == 0">
            No ads to show.
        </div>
        <v-dialog v-model="dialog" width="500">
            <v-card class="pa-4">
                <img :src="ads[selectedId].image.downloadUrl" alt="Banner Ad" style="width:100%" v-if="selectedId">
            </v-card>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue';
//eslint-disable-next-line
import db, { storage } from '@/firebase';
export default {
    components: { BaseLayout },
    data() {
        return {
            ads: {},
            enabled: true,
            downloadUrls: {},
            selectedId: null,
            dialog: false,
            overlay: false
        }
    },
    computed: {
        type() {
            return this.$route.params.type;
        },
        storageRef() {
            return storage.ref(`advertisements/${this.type}`)
        },
    },
    methods: {
        pushAd(ad) {
            this.ads = { ...this.ads, ...ad };
        },
        convertURL(url) {
            return btoa(url);
        },
        showDialog(id) {
            this.selectedId = id;
            this.dialog = true;
        },
        switchStatus() {
            const statusRef = db.ref(`advertisements/${this.type}/enabled`);
            statusRef.set(this.enabled);
        }
    },
    // eslint-disable-next-line
    beforeRouteEnter(to, from, next) {
        if (!['banner', 'carousel'].includes(to.params.type)) {
            return next(false);
        }
        next(true);
    },
    // eslint-disable-next-line
    beforeRouteUpdate(to, from, next) {
        if (!['banner', 'carousel'].includes(to.params.type)) {
            return next(false);
        }
        next(true);
    },
    async created() {
        this.overlay = true;
        const ref = db.ref(`advertisements/${this.type}/items`);
        const statusSnapshot = await db.ref(`advertisements/${this.type}/enabled`).once('value');
        this.enabled = statusSnapshot.val();
        const snapshot = await ref.once('value')
        const val = snapshot.val();
        if (val) {
            Object.entries(val).forEach(([key, value]) => {
                this.pushAd({ [key]: value })
            })
        }
        ref.on('value', (snapshot) => {
            const val = snapshot.val();
            if (!val) return;
            this.pushAd(snapshot.val());
        });
        this.overlay = false;
    }
}
</script>

<style>
.card-leading {
    width: 100%;
    /* height: 100%; */
}
</style>
<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <SnackBar></SnackBar>
  </v-app>
</template>

<script>
import SnackBar from './components/SnackBar.vue';
export default {
  name: 'App',
  components: { SnackBar },
  data: () => ({
    //
  }),
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}

.v-application {
  font-family: 'Albert Sans', sans-serif !important;
  /* background: #000000 !important; */
}
</style>